import gql from 'graphql-tag';

export const GetPaymentMethodsQuery = gql`
  query paymentMethods($filter: PaymentMethodFilterInput!) {
    paymentMethods(filter: $filter) {
      edges {
        node {
          id
          name
          code
          description
          label
          minUsdAmount
          maxUsdAmount
          currency
          discountAmount
          discountValueType
          image(size: "300x120") {
            url
          }
          gateway {
            name
          }
          __typename
        }
      }
    }
  }
`;

export const PaymentCountriesQuery = gql`
  query PaymentCountries {
    paymentCountries {
      userCountryCode
      availableCountryCodes
    }
  }
`;

import React, {createContext, useState} from 'react';
import dynamic from 'next/dynamic';
import {ThemeProvider} from '@material-ui/core/styles';

import {
  CUSTOM_MODAL,
  FORGOT_PASSWORD_MODAL,
  LOGIN_MODAL,
  REGISTER_MODAL
} from 'src/config';
import {themeNext} from 'src/themes/themeNext';

const LoginModal = dynamic(
  () => import('src/views/HeaderNext/components/LoginModal')
);
const SignupModal = dynamic(
  () => import('src/views/HeaderNext/components/SignupModal')
);
const ForgotPasswordModal = dynamic(
  () => import('src/views/HeaderNext/components/ForgotPasswordModal')
);
const CustomModal = dynamic(() => import('src/componentsNext/CustomModal'));

const ModalContext = createContext();

export const ModalProvider = ({children}) => {
  const [{modalType, title, content, width, actions}, setModalSettings] =
    useState({});

  const hideModal = () => {
    setModalSettings({});
  };

  const showModal = (type, title, content, width, actions) => {
    setModalSettings({
      modalType: type,
      title,
      content,
      width,
      actions
    });
  };

  return (
    <ModalContext.Provider
      value={{
        showModal,
        hideModal
      }}
    >
      <>
        {children}
        {modalType === LOGIN_MODAL && (
          <ThemeProvider theme={themeNext}>
            <LoginModal open={modalType === LOGIN_MODAL} onClose={hideModal} />
          </ThemeProvider>
        )}
        {modalType === REGISTER_MODAL && (
          <ThemeProvider theme={themeNext}>
            <SignupModal
              open={modalType === REGISTER_MODAL}
              onClose={hideModal}
            />
          </ThemeProvider>
        )}
        {modalType === FORGOT_PASSWORD_MODAL && (
          <ThemeProvider theme={themeNext}>
            <ForgotPasswordModal
              open={modalType === FORGOT_PASSWORD_MODAL}
              onClose={hideModal}
            />
          </ThemeProvider>
        )}
        {modalType === CUSTOM_MODAL && (
          <ThemeProvider theme={themeNext}>
            <CustomModal
              open={modalType === CUSTOM_MODAL}
              onClose={hideModal}
              title={title}
              width={width}
              actions={actions}
            >
              {content}
            </CustomModal>
          </ThemeProvider>
        )}
      </>
    </ModalContext.Provider>
  );
};

export const ModalContextConsumer = ModalContext.Consumer;
export default ModalContext;

import React, { createContext, useEffect, useRef, useState } from 'react';
import TagManager from 'react-gtm-module';
import {
  ADD_TO_CART,
  BEGIN_CHECKOUT,
  PURCHASE,
  REGISTRATION
} from 'src/core/googleEvents';

const dataLayerKey = 'dataLayer';
const dataLayerID = 'notfound';
const DataLayerContext = createContext({});
let isGtmInit = false;

const getDataLayerID = data => {
  var key = dataLayerID;
  var idMatcher = [BEGIN_CHECKOUT, ADD_TO_CART, REGISTRATION];
  if (idMatcher.includes(data.event)) {
    key = data.event + ':' + data.id;
  } else if (data.event === PURCHASE) {
    key = data.event + ':' + data.ecommerce.transactionId;
  }
  return key;
};

export const GoogleProvider = ({ children }) => {
  const isMounted = useRef(true);
  const [dataLayer, setDataLayer] = useState({});

  useEffect(() => {
    // const gtmInit = Boolean(sessionStorage.getItem(gtmInitKey));
    // make usre that we load GTM only once
    if (isMounted.current && !isGtmInit) {
      // eslint-disable-next-line no-undef
      const { GTM_ID } = process.env;
      // init GTM
      const tagManagerArgs = {
        gtmId: GTM_ID,
        dataLayer
      };
      if (GTM_ID) {
        // delay GTM init to improve site speed score
        setTimeout(() => {
          TagManager.initialize(tagManagerArgs);
        }, 3000);
        // mark that TagManager is init
        isGtmInit = true;
      }

      var dataLayerSession = sessionStorage.getItem(dataLayerKey);
      if (!dataLayerSession) {
        // create session storage to store events we already sent
        // to avoid duplicates on GTM side
        sessionStorage.setItem(dataLayerKey, JSON.stringify({}));
      }
    }

    // remove session key on page refresh
    // window.addEventListener('beforeunload', () => sessionStorage.removeItem(gtmInitKey));

    // init GA
    // ReactGA.initialize(GA_MEASUREMENT_ID);
    // ReactGA.pageview(window.location.pathname + window.location.search);
    isMounted.current = false;
  }, [dataLayer]);
  return (
    <DataLayerContext.Provider
      value={{
        dataLayer,
        pushDataLayer: values => {
          // make sure same event wasn't already pushed before
          var dataLayerSession = sessionStorage.getItem(dataLayerKey);
          if (!dataLayerSession) {
            return;
          }
          dataLayerSession = JSON.parse(dataLayerSession);
          const dataID = getDataLayerID(values);
          if (
            dataLayerSession[values.event] === dataID ||
            dataID === dataLayerID
          ) {
            return;
          }

          dataLayerSession[values.event] = dataID;
          sessionStorage.setItem(
            dataLayerKey,
            JSON.stringify(dataLayerSession)
          );

          // clear manually added ID field
          if (values.id) {
            delete values.id;
          }
          TagManager.dataLayer({ dataLayer: { ecommerce: null } });
          TagManager.dataLayer({ dataLayer: values });
        },
        setDataLayer: values => setDataLayer(values) // do not use it
      }}
    >
      {children}
    </DataLayerContext.Provider>
  );
};

export const DataLayerConsumer = DataLayerContext.Consumer;
export default DataLayerContext;
